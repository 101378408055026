import React, { useEffect } from "react";
import { Seo } from "../../components/seo";
import SuccessBooking from "../../components/SuccessBooking/SuccessBooking";
import Layout from "../../hoc/Layout/Layout";

// markup
const IndexPage = ({ location }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "0befd6bd-6cee-48d1-a7dd-b42858b7e2e8";
      let d = document;
      let s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    }
  }, []);

  return (
    <Layout>
      <Seo title="Tack för att du har bokat ett startmöte!" />
      <SuccessBooking location={location} />
    </Layout>
  );
};

export default IndexPage;
